import { theme } from '../Theming/Theme';

export const HallmarkBorder = `
  font-size: 1.6rem;
  margin-bottom: 1.5rem;
  font-weight: ${theme.font.weights.bold};
  &:after {
    margin: 1rem auto 0;
    content: ' ';
    display: block;
    border-bottom: ${theme.general.hallmarkBorder};
    max-width: 50px;
  }
`;

//FULL WIDTH CONTAINER INSIDE LIMITED WIDTH
export const fullWidth = `
  @media (min-width: ${theme.general.maxWidth}) {
    margin-left: calc(-100vw / 2 + ${theme.general.maxWidth} / 2);
    margin-right: calc(-100vw / 2 + ${theme.general.maxWidth} / 2);
  }
`;

//CUSTOM SCROLL BAR
export const scrollBar = `
  overflow: auto;
  padding-right: 5px;
  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${theme.colors.greys[1]};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.greys[2]};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${theme.colors.greys[3]};
  }
`;
