import React from 'react';
import ItemCustom from './ItemCustom';
import { styled } from 'linaria/react';
import { theme } from '../Theming/Theme';

const HeroAreaWrapper = styled('div')`
  margin-bottom: 2%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > div {
    width: 100%;
    ${theme.below.sm} {
      > * {
        display: block;
        margin-bottom: 3%;
      }
    }
    ${theme.only.sm} {
      &.primary {
        margin-bottom: 2%;
      }
      &.secondary {
        width: 100%;
        display: flex;
        justify-content: space-between;
        > * {
          width: 49%;
        }
      }
    }
    ${theme.above.md} {
      &.primary {
        width: 66.3%;
      }
      &.secondary {
        display: flex;
        width: 32.666%;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
`;

const HeroArea = ({ row, className }) => {
  return (
    <HeroAreaWrapper className={className}>
      <div className="primary">
        <ItemCustom item={row.items[0].item} aspect="4:3" />
      </div>
      <div className="secondary">
        <ItemCustom item={row.items[1].item} aspect="400:297" />
        <ItemCustom item={row.items[2].item} aspect="400:297" />
      </div>
    </HeroAreaWrapper>
  );
};

export default HeroArea;
