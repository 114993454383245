import React, { useContext } from 'react';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { styled } from 'linaria/react';
import { useAddwish } from '@jetshop/flight-addwish';
import AddwishRow from './AddwishRow/AddwishRow';

const AddwishWrapper = styled('div')`
  margin: 3% 0;
`;

const AddwishStart = ({ id }) => {
  //ADDWISH
  const { selectedChannel } = useContext(ChannelContext);
  const { addWishResponseBoxItems } = useAddwish({
    boxes: {
      [id]: {
        url: selectedChannel.url
      }
    },
    pageLoaded: true
  });

  return (
    <AddwishWrapper>
      <AddwishRow data={addWishResponseBoxItems[id]} id={id} />
    </AddwishWrapper>
  );
};

export default AddwishStart;
