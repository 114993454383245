import React, { Component, Fragment } from 'react';
import { styled } from 'linaria/react';
import ItemCustom from './ItemCustom';
import ProductCard from '../CategoryPage/ProductCard';

const ItemWrapper = styled('li')`
  display: block;
  position: relative;
`;

class Item extends Component {
  generateItemElement(item, columns, aspect) {
    let element = <Fragment />;
    switch (item.__typename) {
      case 'Product':
        element = <ProductCard displayColorCircles={true} product={item} />;
        break;
      case 'CustomItem':
        element = <ItemCustom item={item} columns={columns} aspect={aspect} />;
        break;
      default:
      // Do nothing
    }
    return <Fragment>{element}</Fragment>;
  }
  render() {
    const { item, columns, aspect } = this.props;
    return (
      <ItemWrapper>
        {this.generateItemElement(item, columns, aspect)}
      </ItemWrapper>
    );
  }
}

export default Item;
