import React, { Fragment } from 'react';
import { styled } from 'linaria/react';

const backgroundColor = '#EEE';

const MockImage = styled('div')`
  padding-top: 50%;
  background: ${backgroundColor};
`;

const MockProductWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1%;
`;

const MockProduct = styled('div')`
  padding-top: 25%;
  background: ${backgroundColor};
  width: 49.5%;
  margin: 1% 1% 0 0;
  &:nth-of-type(2n) {
    margin-right: 0;
  }
`;

export default React.memo(function StartPageLoadingState() {
  return (
    <Fragment>
      <MockImage className="full-width" />
      <MockProductWrapper>
        <MockProduct />
        <MockProduct />
        <MockProduct />
        <MockProduct />
      </MockProductWrapper>
    </Fragment>
  );
});
