import t from '@jetshop/intl';
import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import AddwishRow from './StartPage/AddwishRow/AddwishRow';
import { useAddwish } from '@jetshop/flight-addwish';
import MaxWidth from './Layout/MaxWidth';
import { config } from '../shop.custom.config';
import ChannelContext from '@jetshop/core/components/ChannelContext/ChannelContext';
import { useLocation } from 'react-router';

const ErrorContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 6vh 2rem;
  align-items: center;
`;

const ErrorTitle = styled('span')`
  font-style: normal;
  font-size: 40px;
  line-height: 44px;
  color: #e82a2a;
`;

const ErrorContent = styled('span')`
  font-style: medium;
  font-size: 16px;
  line-height: 26px;
  padding: 48px 0px;
  font-weight: bold;
`;

const ErrorBody = styled('div')`
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: #999999;
  max-width: 43em;
  padding-bottom: 4em;
`;

const P = styled('p')`
  margin-bottom: 12px;
  strong {
    font-weight: bold;
  }
`;

const NotFoundPage = () => {
  //ADDWISH
  const { selectedChannel } = useContext(ChannelContext);
  const location = useLocation();
  const url = selectedChannel.url + location.pathname;

  const { addWishResponseBoxItems } = useAddwish({
    boxes: {
      [config.addwish.notFoundPage[0]]: {
        url: url
      },
      [config.addwish.notFoundPage[1]]: {
        url: url
      }
    },
    pageLoaded: true
  });

  return (
    <MaxWidth>
      <ErrorContainer>
        <ErrorTitle>{t('Whoops')}</ErrorTitle>
        <ErrorContent>
          {t(`You have attempted to download a page that does not exist on Jetshop's
          server.`)}
        </ErrorContent>
        <ErrorBody>
          {' '}
          <P>
            <strong>{t('This could be due to the following:')}</strong>
          </P>
          <P>
            {t(
              'The address provided is incorrect. Please check the spelling and try again.'
            )}
          </P>
          <P>
            {t(
              'You are trying to use an old address that no longer exists or that may have changed.'
            )}
          </P>
          <P>
            {t(
              'You may have used an old bookmark. If you are sure that you have used the correct address to the site, the problem may be caused by a temporary service disruption. Please try again later.'
            )}
          </P>
          <P>
            {t(
              'NOTE! If you were directed here via a link we are grateful if you inform us as to which link it was so that we can resolve the problem.'
            )}
          </P>
        </ErrorBody>
        <Link to="/">{t('HOMEPAGE')}</Link>
      </ErrorContainer>
      <div style={{ margin: '2% 0 5%' }}>
        <AddwishRow
          data={addWishResponseBoxItems[config.addwish.notFoundPage[0]]}
          title={'Just for you'}
        />
      </div>
      <AddwishRow
        data={addWishResponseBoxItems[config.addwish.notFoundPage[1]]}
        title={'Our bestsellers'}
      />
    </MaxWidth>
  );
};

export default NotFoundPage;
