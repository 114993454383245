import React, { Fragment } from 'react';
import startPageQuery from './StartPageQuery.gql';
import { Query } from 'react-apollo';
import Render from './Render';
import { styled } from 'linaria/react';
import { fullWidth } from '../ui/General';
import Head from '@jetshop/core/components/Head';
import StartPageLoadingState from './StartPageLoadingState';

const StartPageWrapper = styled('div')`
  width: 100%;
  padding: 0 0 2rem;
  display: flex;
  flex-direction: column;
  .full-width {
    ${fullWidth}
  }
`;

const StartPage = ({ result }) => {
  const { loading, data } = result;
  if (loading) return <StartPageLoadingState />;
  if (!data) return null;

  return (
    <Fragment>
      <StartPageWrapper>
        {result.data.startPage && result.data.startPage.head && (
          <Head data={result.data.startPage.head} />
        )}
        <Render result={result} />
      </StartPageWrapper>
    </Fragment>
  );
};

const StartPageComp = ({ startPageId }) => {
  //startPageId = 473;
  return (
    <Fragment>
      <Query
        variables={startPageId && { startPageId: startPageId }}
        query={startPageQuery}
      >
        {result => <StartPage result={result} />}
      </Query>
    </Fragment>
  );
};

export default StartPageComp;
